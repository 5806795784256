import styled from '@emotion/styled';
import { Wrapper } from '../../components';

export const Hero = styled.section`
  padding: 2rem 0;
  margin-bottom: 3rem;
  background-color: ${props => props.theme.colors.greyLight};
  background-position: center;
  background-size: cover;
  display: flex;
  position: relative;
  align-items: center;
  align-content: center;
  z-index: 0;
`;

export const FlexWrapper = styled(Wrapper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: flex;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
`;

export const Col = styled.div`
  position: relative;
  z-index: 100;
  flex-basis: calc(99.9% * 1 / 2 - 1.5rem);
  max-width: calc(99.9% * 1 / 2 - 1.5rem);
  width: calc(99.9% * 1 / 2 - 1.5rem);
  text-align: ${props => (props.center ? 'center' : '')};
  text-align: ${props => (props.right ? 'right' : '')};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 1rem;
    flex-basis: calc(99.9% * 1 / 2 - 1rem);
    max-width: calc(99.9% * 1 / 2 - 1rem);
    width: calc(99.9% * 1 / 2 - 1rem);
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: ${props => (props.noMobile ? 'none' : '')};
  }
`;

export const StyledAvatar = styled.div`
  display: flex;
  padding: 2rem 0;

  .gatsby-image-outer-wrapper,
  .gatsby-image-wrapper {
    width: 246px;
    height: 246px;
    margin: 0 auto;
    transition: all 0.5s;
    display: flex;
    justify-content: center;

    img {
      max-width: 246px;
      max-height: 246px;
      display: inline-block;
      border-radius: 50%;
      border: 4px solid ${props => props.theme.colors.white};
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledName = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
`;

export const StyledJob = styled.div`
  padding: 0.6rem 0;
  font-style: italic;
  font-size: 1rem;
  color: ${props => props.theme.colors.greyDark};
`;