import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SocialListItem from './SocialListItem';

const List = styled.div`
  //display: flex;
  //justify-content: left;
  //align-items: center;
  //margin-top: 0.4rem;
  //margin-bottom: 0.4rem;
  //list-style-type: none;
  //margin-left: 0;
  //font-size: 1.8rem;
  background-color: red;
`;

export default class SocialList extends Component {
  render() {
    const { social } = this.props;
    return (
      <List>
        {social.map(soc => (
          <SocialListItem key={soc.id} node={soc} />
        ))}
      </List>
    );
  }
}

SocialList.propTypes = {
  social: PropTypes.array.isRequired,
};
