import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { FaTwitter, FaGithub, FaLinkedin, FaRegStar } from 'react-icons/fa';

const Item = styled.div`
height: 20px;
width: 20px;
font-size: 1rem;
  margin-right: 1rem;
  //display: flex;
  //justify-content: space-around;
  color: red;

  // @media (max-width: ${props => props.theme.breakpoints.m}) {
  //   display: flex;
  // }
  // @media (max-width: ${props => props.theme.breakpoints.s}) {
  //   display: block;
  // }
  background-color: blue;
`;

const StyledSubLink = styled.a`
  color: ${props => props.theme.colors.greyDark};
  
  transition: all 0.5s;

  &:hover {
    &.Twitter {
      color: ${props => props.theme.colors.twitter};
    }
    &.Linkedin {
      color: ${props => props.theme.colors.linkedin};
    }
    &.Github {
      color: ${props => props.theme.colors.github};
    }
    &.Web {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export default class SocialListItem extends Component {
  render() {
    const { node } = this.props;
    const link = node.link.url;
    return (
      <Item>
        <StyledSubLink
          target={node.link.target}
          className={node.name1}
          title={`Retrouvez moi sur ${node.name1}`}
          href={link}
        >
          {node.name1 === 'Web' ? FaRegStar() : ''}
          {node.name1 === 'Twitter' ? FaTwitter() : ''}
          {node.name1 === 'Linkedin' ? FaLinkedin() : ''}
          {node.name1 === 'Github' ? FaGithub() : ''}
        </StyledSubLink>
      </Item>
    );
  }
}

SocialListItem.propTypes = {
  node: PropTypes.object.isRequired,
};
