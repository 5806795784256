import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Layout, ListingCard, SEO, Header, SliceZone, Wrapper } from '../../components';
import website from '../../../config/website';
import { Col, Hero, FlexWrapper, StyledAvatar, StyledName, StyledJob } from './Elements';
import SocialList from '../../components/SocialList';

const Person = ({ data: { prismicPerson, posts, totalCount }, location }) => {
  const { data } = prismicPerson;

  return (
    <Layout location={location}>
      <SEO
        title={`${data.name} | ${website.title}`}
        pathname={location.pathname}
        desc={data.bio.text}
        node={prismicPerson}
        // article
      />
      <Header light />
      <Hero>
        <FlexWrapper>
          <Col>
            <StyledAvatar>
              <Img fluid={data.image.big.localFile.childImageSharp.fluid} alt={data.image.big.alt} title={data.name} />
            </StyledAvatar>
          </Col>
          <Col>
            <StyledName>{data.name}</StyledName>
            <StyledJob>{data.job}</StyledJob>
            <div dangerouslySetInnerHTML={{ __html: data.bio.html }} />
            <SocialList social={data.social} />
          </Col>
        </FlexWrapper>
      </Hero>
      <>
        <Wrapper id={website.skipNavId}>
          <div>
            {posts.totalCount} {posts.totalCount === 1 ? 'publication' : 'publications'}{' '}
            {posts.totalCount === 1 ? 'écrite' : 'écrites'} par {data.name}.
          </div>
          <ListingCard posts={posts.edges} />
        </Wrapper>
      </>
    </Layout>
  );
};

export default Person;

Person.propTypes = {
  data: PropTypes.shape({
    prismicPerson: PropTypes.object.isRequired,
    posts: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PersonPage($auteur: String!) {
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: { data: { authors: { elemMatch: { author: { uid: { eq: $auteur } } } } } }
    ) {
      totalCount
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicPerson(uid: { eq: $auteur }) {
      uid
      data {
        name
        bio {
          html
          text
        }
        job
        image {
          big {
            url
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        social {
          link {
            link_type
            url
            target
          }
          name1
        }
      }
    }
  }
`;
